<script>
export default {
  props: ["page", "pages", "pageSize", "totalRecords", "condensed"],
  emits: ["pageSizeChange", "pageChange"],
  data() {
    return {
      availablePageSizes: [ 10, 25, 50, 100, 200 ],
      currentPage: this.page,
      currentPageSize: this.pageSize
    }
  },
  computed: {
    pageLabels() {
      const list = [];
      for (let i = 0; i < this.pages; i ++) {
        list.push(String(i + 1));
      }
      return list;
    },
    isFirstPage() {
      return this.page === 0;
    },
    isLastPage() {
      return this.page >= this.pages - 1;
    },
    displayPage() {
      return this.page + 1;
    },
    displayLowerRecord() {
      return (this.page * this.currentPageSize) + 1;
    },
    displayUpperRecord() {
      let upper = (this.page * this.currentPageSize) + this.currentPageSize;
      if (upper > this.totalRecords) {
        upper = this.totalRecords;
      }
      return upper;
    }
  },
  methods: {
    changePageSize() {
      this.$emit("pageSizeChange", this.currentPageSize)
    },
    go(toPage) {
      if (toPage && (toPage >= 1 && toPage <= this.pages)) {
        this.currentPage = toPage - 1
        this.$emit("pageChange", this.currentPage);
      }
    },
    firstPage() {
      if (this.currentPage > 0) {
        this.currentPage = 0;
      }
      this.$emit("pageChange", this.currentPage);
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
      this.$emit("pageChange", this.currentPage);
    },
    nextPage() {
      if (this.currentPage < this.pages - 1) {
        this.currentPage++;
      }
      this.$emit("pageChange", this.currentPage);
    },
    lastPage() {
      if (this.currentPage < this.pages - 1) {
        this.currentPage = this.pages - 1;
      }
      this.$emit("pageChange", this.currentPage);
    }
  }
};
</script>

<template>
<div class="pagination">
  <div class="pagingItemsPerPage" v-if="!condensed">
    <label>
    Items per page:&nbsp;
    <select @change="changePageSize($event.target.value)" v-model="currentPageSize">
      <option v-for="availablePageSize in availablePageSizes" :value="availablePageSize" :key="availablePageSize">{{ availablePageSize }}</option>
    </select>
    </label>
  </div>
  <div class="pagingGoToPage" v-if="!condensed">
    <label>
    Go to page:
    <input type="number" min="0" :max="pages" @input.prevent="go($event.target.value)" v-model="displayPage" style="width:50px"/>
    </label>
  </div>
  <div class="pagingRecordCount" v-if="!condensed">
    Showing page {{ this.displayPage }} of {{ this.pages ? this.pages : "1" }} ({{ this.displayLowerRecord ? this.displayLowerRecord : "0" }} to {{ this.displayUpperRecord ? this.displayUpperRecord : "0" }} of {{ this.totalRecords ? this.totalRecords : "0" }} items)
  </div>
  <div class="pagingRecordCount" v-if="condensed">
    Page {{ this.displayPage }} of {{ this.pages ? this.pages : "1" }} ({{ this.totalRecords ? this.totalRecords : "0" }} items)
  </div>
  <div class="pagingNavigation">
  <ul v-if="pages > 1" class="pagination">
    <li v-if="!isFirstPage"><a href="#" @click.prevent="firstPage"><i class="material-icons"> first_page </i></a></li>
    <li v-if="!isFirstPage"><a href="#" @click.prevent="previousPage"><i class="material-icons"> navigate_before </i></a></li>
    <li v-if="!isLastPage"><a href="#" @click.prevent="nextPage"><i class="material-icons"> navigate_next </i></a></li>
    <li v-if="!isLastPage"><a href="#" @click.prevent="lastPage"><i class="material-icons"> last_page </i></a></li>
  </ul>
  </div>
</div>
</template>

<style lang="scss" scoped>
div.pagination {
  display: flex;
  font-size:0.8rem;
}
div.pagingItemsPerPage {
  float:left;
  flex: 25%;
  padding: 0.8rem;

  select {
    flex: 1 1 auto;
    background: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    color: black;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}  
div.pagingGoToPage {
  float:left;
  flex: 25%;
  padding: 0.8rem;

  input[type=number] {
    flex: 1 1 auto;
    background: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    color: black;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}
div.pagingRecordCount {
  float:left;
  flex: 25%;
  padding: 1.4rem;
}
div.pagingNavigation {
  float:right;
  flex: 25%;
  padding: 0.4rem;
}
ul.pagination {
  float: right;
  display: flex;
  flex-wrap: wrap;

  a {
    display: block;
    padding: 0.5rem;
    font-weight: bold;
    color: #212121;
  }

  a:hover {
    background-color: #1275CC;
    color: white;
  }

  li.active a {
    background-color: #002856;
    color: white;
  }
}
</style>
