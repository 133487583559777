import { apiMixins } from "./api";

const FILE_ENDPOINT = "item/file";

export const fileMixins = {
  mixins: [apiMixins],
  methods: {
    async renameFile(item, name) {
      await this.callAPI(`${FILE_ENDPOINT}/${item.id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name }),
      });
    },
    async moveFile(item, parentItem) {
      await this.callAPI(`${FILE_ENDPOINT}/${item.id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ parentId: parentItem.id }),
      });
    },
    async deleteFile(item) {
      await this.callAPI(`${FILE_ENDPOINT}/${item.id}`, { method: "DELETE" });
    },
    async getFileDownloadUrl(item) {
      const data = await this.callAPI('item/download-token', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ids: [item.id] }),
      });
      return data.urls[item.id];
    },
    async createFile({ parentItem, name, subPath, retentionSchedule, size, isSecret, contentType, tags, metadata }) {
      if (typeof subPath === 'string') {
        subPath = subPath.replace(/\\/g, '/').split('/').filter((p) => p);
      }
      const data = await this.callAPI(FILE_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          parentId: parentItem.id,
          name,
          subpath: subPath,
          retentionSchedule,
          size,
          isSecret,
          fileMimeType: contentType,
          tags,
          metadata,
        }),
      });
      return {
        id: data.id,
        uploadUrl: data.url,
        uploadId: data.uploadId,
      };
    },
    async finaliseFile({ id }) {
      await this.callAPI(FILE_ENDPOINT + "/" + id + "/finalise", {
        method: "POST",
      });
    },
    async getExpiredFiles({ page = 0, pageSize = 0, sort, showLoader = true } = {}){
      return await this.callAPI(`${FILE_ENDPOINT}/expired`, {
        method: "GET",
        query: {page, pageSize,sort},
        showLoader,
      });
    },
    async updateFile(item, {suspensionOrders, retentionSchedule, isSecret, steward, owner, tags, retainHistoric, metadata }) {
      let suspensionOrdersList = null;
      if (suspensionOrders || suspensionOrders === '') {
        suspensionOrdersList = suspensionOrders.length === 0 ? [] : suspensionOrders.split(",");
      }
      await this.callAPI(`${FILE_ENDPOINT}/${item.id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ suspensionOrders: suspensionOrdersList, retentionSchedule, isSecret, steward, owner, tags, retainHistoric, metadata }),
      });
    }
  },
};
