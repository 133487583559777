<script>
export default {
  props: {
    defaultButtons: {
      type: Boolean,
      default: true,
    },
    allowCancel: {
      type: Boolean,
      default: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      isConfirming: false,
    };
  },
  mounted() {
    // work around a bug in material-components-vue
    this.isOpen = false;
    this.$nextTick(() => {
      this.isOpen = true;
    });
  },
  methods: {
    async confirm() {
      if (!this.valid || this.isConfirming || !this.$listeners.confirmed) {
        return;
      }
      this.isConfirming = true;
      try {
        await this.$listeners.confirmed();
      } finally {
        this.isConfirming = false;
      }
    },
  },
};
</script>

<template>
  <!-- dialog-holder exists to work around bad decisions in the material-components-vue library
       if the library were swapped for another modal implementation, this could be simplified. -->
  <div class="dialog-holder" @click.stop>
    <m-dialog
        class="dialog"
        :escapeKeyAction="allowCancel ? 'close' : ''"
        scrimClickAction=""
        v-model="isOpen"
        @closed="$emit('cancelled')"
    >
      <form @submit.prevent="confirm">
        <header>
          <m-icon v-if="icon" :icon="icon" class="icon" />
          <span class="title">
            <slot name="header" />
          </span>
          <!-- tabindex -1 used here to make focus go to useful elements first;
               this function is still accessible by pressing Escape -->
          <button v-if="allowCancel" type="button" @click.stop="$emit('cancelled')" tabindex="-1">
            <m-icon icon="close" />
          </button>
        </header>
        <section>
          <slot name="body" />
        </section>
        <footer v-if="defaultButtons || $slots.footer">
          <slot name="footer" />
          <button v-if="defaultButtons && allowCancel" type="button" @click.stop="$emit('cancelled')">
            <slot name="cancelLabel">{{ $t("cancel") }}</slot>
          </button>
          <button v-if="defaultButtons" class="primary" type="submit" :disabled="isConfirming || !valid">
            <slot name="confirmLabel">{{ $t("ok") }}</slot>
          </button>
        </footer>
      </form>
    </m-dialog>
  </div>
</template>

<style lang="scss" scoped>
.dialog-holder {
  visibility: visible !important;
  font-size: 1rem;
  cursor: auto;
  text-align: left;
  position: absolute;
}

form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 0.875rem;
}

header {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #EAEAEA;

  .icon {
    flex: 0;
    margin-right: 1rem;
  }

  .title {
    flex: 1 0 auto;
    font-weight: bold;
  }

  button {
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    margin: -1rem -1rem -1rem 1rem;
    cursor: pointer;
    text-align: center;

    i {
      vertical-align: middle;
    }
  }
}

section {
  padding: 1.5rem;

  > ::v-deep .mdc-text-field {
    width: 100%;
    box-sizing: border-box;
    margin: 1rem 0 0;
    &:first-child {
      margin-top: 0.2rem;
    }
  }

  > ::v-deep .popup-section {
    margin: -1.5rem -1.5rem 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid #EAEAEA;

    &:last-child {
      border-bottom: none;
      margin-bottom: -1.5rem;
    }
  }
}

footer {
  padding: 0 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

footer > * {
  margin-left: 1.5rem;
}

footer button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}

::v-deep .mdc-dialog {
  .mdc-dialog__surface {
    min-width: 30rem;
    max-width: 50rem;
    @media (max-width: 62rem) {
      max-width: calc(100vw - 2rem);
    }
    @media (max-width: 32rem) {
      min-width: 0;
      width: 100vw;
    }
    box-shadow: none;
    border-radius: 4px;
  }

  .mdc-dialog__scrim {
    background: rgb(81, 81, 81, 0.3);
  }
}
</style>
