import {apiMixins} from "./api";

const TEAM_ENDPOINT = "team";
const USER_ENDPOINT = "users";

export const userMixins = {
  mixins: [apiMixins],
  methods: {
    async getUserTeams() {
      throw new Error('not supported');
    },
    async getUsers({ page = 0, pageSize = 0, sort, showLoader = true, filter="" }) {
      return await this.callAPI(USER_ENDPOINT, {
        method: "GET",
        query: {page, pageSize, sort, filter},
        showLoader,
      });
    },
    async getTeams({ page = 0, pageSize = 0, sort, showLoader = true, filter="" } = {}) {
      return await this.callAPI(TEAM_ENDPOINT, {
        method: "GET",
        query: {page, pageSize, sort, filter},
        showLoader,
      });
    },
    async getTeamsForUser({ showLoader = false, userId = 0} = {}) {
      return await this.callAPI(`${TEAM_ENDPOINT}/membership/${userId}`, {
        method: "GET",
        showLoader,
      });
    },
    async addNewTeam(name) {
      await this.callAPI(TEAM_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "name" : name }),
      });
    },
    async updateUserRole() {
      throw new Error('not supported');
    },
    async postTeamShare() {
      throw new Error('not supported');
    },
    async postUserShare() {
      throw new Error('not supported');
    },
    async getTeamMembers(id) {
      return await this.callAPI(`${TEAM_ENDPOINT}/${id}`, {
        method: "GET",
      });
    },
    async deactivateUser(userId) {
      return await this.callAPI(`${USER_ENDPOINT}/${userId}`, {
        method: "DELETE",
      });
    },
    async deleteTeam() {
      throw new Error('not supported');
    },
    async removeUserFromTeam() {
      throw new Error('not supported');
    },
    async addUsersToTeam(teamId, members) {
      await this.callAPI(`${TEAM_ENDPOINT}/${teamId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "members" : members }),
      });
    },
    async setTeamSteward(teamId, steward) {
      await this.callAPI(`${TEAM_ENDPOINT}/${teamId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "steward": steward }),
      });
    },
    async checkTeam({ q } = {}) {
      return await this.callAPI(`${TEAM_ENDPOINT}/exists`, {
        method: "GET",
        query: {q},
      });
    },
  },
};
