<script>
export default {
  data() {
     return {
       filterValue: ""
    };
  },
  methods: {
      setFilter(filter) {
        if (!filter || filter.length >= 3) {
          this.$emit("filterChange", filter);
        }
      }
  },
  watch:{
    $route (to, from){
      if (to !== from) 
        this.filterValue = ""
        this.setFilter("")
      }
    }
};
</script>

<template>
  <input class="filter" type="text" @input="setFilter($event.target.value)" placeholder="Filter Results" v-model="filterValue"/>
</template>

<style lang="scss" scoped>
input.filter {
  flex: 1 1 auto;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  color: black;
  padding: 0.5rem 1rem;
}
</style>>
