export function addYears(date, years) {
  const date2 = new Date(date);
  date2.setUTCFullYear(date2.getUTCFullYear() + years);
  return date2;
}

export function toISODate(date) {
  // returns YYYY-MM-DD
  return date.toISOString().split('T')[0];
}

export function formatISODate(isoDate){
  const parsedDate = new Date(isoDate);
  const date = parsedDate.toISOString().split('T')[0];
  const time = parsedDate.toTimeString().split(' ')[0];
  return `${date} ${time}`;
}
